import React from "react"
import { graphql } from "gatsby"
import * as style from "../pages/about.module.sass"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import noel from "../data/aycanoel.jpg"

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <div
        style={{
          height: "calc(var(--spacing-40)*2.5",
        }}
      />
      <GatsbyImage
        className={style.img}
        alt="noel"
        image={data.noel.childImageSharp.gatsbyImageData}
      ></GatsbyImage>

      <div className={style.text}>
        {" "}
        <p>
          Welcome. I am a communication designer with a background in
          psychology. I create three dimensional objects and spaces - both
          digitally and physically. My work focuses around light, texture and
          feel.
          <br></br>
          Currently, I am studying communication design at the University of
          Applied Sciences in Munich, Germany.
        </p>
        <p className={style.textBig}>
          Wanna get in touch? <br></br>
          <a
            href="mailto:hello@aycanoel.com"
            style={{ textDecoration: "underline", fontWeight: "bold" }}
          >
            hello@aycanoel.com
          </a>
        </p>
        <div className={style.box} />
      </div>
    </Layout>
  )
}

export default AboutPage

export const Head = () => {
  return (
    <>
      <title>AycaNoel</title>
      <meta
        name="description"
        content="Welcome. I am a communication designer with a background in
          psychology. I create three dimensional objects and spaces - both
          digitally and physically. My work focuses around light, texture and
          feel.
          Currently, I am studying communication design at the University of
          Applied Sciences in Munich, Germany."
      />
      <meta property="og:url" content="aycanoel.com" />
      <meta property="og:site_name" content="AycaNoel" />
      <meta
        property="og:description"
        content="Welcome. I am a communication designer with a background in
          psychology. I create three dimensional objects and spaces - both
          digitally and physically. My work focuses around light, texture and
          feel.
          Currently, I am studying communication design at the University of
          Applied Sciences in Munich, Germany."
      />
    </>
  )
}
export const query = graphql`
  query {
    noel: file(relativePath: { eq: "aboutaycanoel.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
